import type {
  BusinessScaleObject,
  CompetitorObject,
  ContactObject,
  FavoriteLessonContentObject,
  IndustryObject,
  LessonContentLogObject,
  LessonLogObject,
  LessonObject,
  PrefectureObject,
  UserAgeObject,
  UserObject,
  UserPermissionObject,
  UserPositionObject,
  WebMediaAdoptionObject,
  WebMediaCategoryObject,
  ZipCodeObject,
  CurriculumObject,
} from '../entities';
import type { HTTPMethod, InquiryType } from '../types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = <T>(path: string, body?: any): Promise<T> => fetchAPI<T>(path, 'POST', body);
const put = <T>(path: string, body: any): Promise<T> => fetchAPI<T>(path, 'PUT', body);
const get = <T>(path: string): Promise<T> => fetchAPI<T>(path, 'GET');
const del = <T>(path: string): Promise<T> => fetchAPI<T>(path, 'DELETE');

const fetchAPI = async <T>(
  path: string,
  method?: HTTPMethod,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any
): Promise<T> => {
  const res = await fetch(path, {
    method,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.status === 204) {
    return;
  }
  const data = await res.json();
  return data as T;
};

export interface LoginPayload {
  success: boolean;
  redirectURL?: string;
  error?: string;
}

export interface SuccessPayload {
  success: boolean;
  error?: string;
}

export interface UpdateAccountParams {
  name?: string;
  zipCode?: string;
  prefectureId?: string;
  city?: string;
  address1?: string;
  address2?: string;
  storeBrandName?: string;
  storeName?: string;
  storePhone?: string;
  personalPhone?: string;
  companyName?: string;
  departmentName?: string;
  industryIds?: string[];
  businessScaleId?: string;
  positionId?: string;
  permissionId?: string;
  ageId?: string;
  adoptedCompetitorIds?: string[];
  websiteURL?: string;
  webMediaInterestIds?: string[];
  webMediaAdoptions?: { [id: string]: boolean };
  sharedId?: string;
  sharedUrl?: string;
  isSimpleForm?: boolean;
}

export interface ShortSignUpAccountParams {
  name?: string;
  personalPhone?: string;
  companyName?: string;
  departmentName?: string;
}

export interface CreateContactParams {
  subject: string;
  message: string;
}

export interface CreateGuestContactParams extends CreateContactParams {
  name: string;
  email: string;
  companyName: string;
  departmentName: string;
  storeBrandName: string;
  phone: string;
  positionId: string;
  businessScaleId: string;
  industryIds: string[];
}

export const login = (values: { email: string; password: string }): Promise<LoginPayload> =>
  post<LoginPayload>('/api/login/', values);

export const resendEmailConfirmation = (values: { email: string }): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/resendEmailConfirmation/', values);

export const createPasswordReset = (values: { email: string }): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/passwordResets/', values);

export const confirmPasswordReset = ({
  password,
  token,
}: {
  token: string;
  password: string;
}): Promise<SuccessPayload> => post<SuccessPayload>(`/api/passwordResets/${token}/`, { password });

export const signup = (values: {
  email: string;
  password: string;
  name: string;
  companyName: string;
  departmentName: string;
  personalPhone: string;
  storeName: string;
  industryIds: string[];
  businessScaleId: string;
  firstTouchURL: string;
  lastTouchURL: string;
}): Promise<SuccessPayload> => post<SuccessPayload>('/api/users/', values);

export const skipRegisterSteps = (): Promise<SuccessPayload> => post<SuccessPayload>('/api/users/skipRegister');

export const loginWithGoogle = (values: {
  token: string;
  firstTouchURL: string;
  lastTouchURL: string;
}): Promise<SuccessPayload> => post<SuccessPayload>('/api/users/loginWithGoogle', values);

export const linkWithGoogle = (values: { token: string }): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/users/linkWithGoogle', values);

export const unlinkWithGoogle = async (token: string): Promise<void> => {
  await del<void>(`/api/users/externalIdAuths/${token}/google`);
  return;
};

export const updateAccount = (values: UpdateAccountParams): Promise<UserObject> =>
  put<UserObject>('/api/users/current/', values);

export const updateAdoptedWebMedia = (webMediumId: string, adopted: boolean) =>
  put<{ webMediaAdoption: WebMediaAdoptionObject }>(`/api/users/current/webMedia/${webMediumId}/adoption/`, {
    adopted,
  });

export const updateWebMediaInterest = (webMediumId: string, interested: boolean) =>
  put<void>(`/api/users/current/webMedia/${webMediumId}/interest/`, { interested });

export const updateLastTouchURL = (lastTouchURL: string) =>
  put<void>('/api/users/updateLastTouchURL/', { lastTouchURL });

export const getCurrentUser = (): Promise<UserObject | null> => get<UserObject | null>('/api/users/current/');

export const markBasicInfoAsRegistered = async (): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/users/status/completeBasicInfoRegistered');

export const markWebMediaAdoptionsAsRegistered = async (): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/users/status/completeWebMediaAdoptionsRegistered');

export const markWebMediaInterestsAsRegistered = async (): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/users/status/completeWebMediaInterestsRegistered');

export const getLesson = (id: string): Promise<LessonObject> => get<LessonObject>(`/api/lessons/${id}/`);

export const getNextLesson = (id: string): Promise<LessonObject | null> =>
  get<LessonObject | null>(`/api/lessons/${id}/next/`);

export const getLessonLogs = (id: string): Promise<LessonContentLogObject[]> =>
  get<LessonContentLogObject[]>(`/api/lessons/${id}/logs/`);

export const postLessonLog = (id: string, contentId: string): Promise<LessonContentLogObject[]> =>
  post<LessonContentLogObject[]>(`/api/lessons/${id}/logs/`, { contentId });

export const getFavoriteLessonContents = (id: string): Promise<FavoriteLessonContentObject[]> =>
  get<FavoriteLessonContentObject[]>(`/api/lessons/${id}/favorites/`);

export const getFavoriteLessonContent = (id: string): Promise<FavoriteLessonContentObject | null> =>
  get<FavoriteLessonContentObject | null>(`/api/lessonContents/${id}/favorite/`);

export const putFavoriteLessonContent = (id: string, comment: string): Promise<FavoriteLessonContentObject> => {
  return put<FavoriteLessonContentObject>(`/api/lessonContents/${id}/favorite/`, { comment });
};

export const deleteFavoriteLessonContent = async (id: string): Promise<void> => {
  await del<void>(`/api/lessonContents/${id}/favorite/`);
  return;
};

export const getAllFavoriteLessonContents = async (): Promise<FavoriteLessonContentObject[]> =>
  get<FavoriteLessonContentObject[]>('/api/favoriteLessonContents/');

export const getCurriculum = (id: string, previewAccessToken?: string): Promise<CurriculumObject> =>
  get<CurriculumObject>(`/api/curriculums/${id}/?previewAccessToken=${previewAccessToken}`);

export const getCurriculumLessonLogs = async (id: string): Promise<LessonLogObject[]> =>
  get<LessonLogObject[]>(`/api/curriculums/${id}/lessonLogs/`);

export const getLessonLog = async (id: string): Promise<LessonLogObject> =>
  get<LessonLogObject>(`/api/lessonLogs/${id}/`);

export const favoriteWhitepaper = async (id: string): Promise<void> => {
  await put<void>(`/api/whitepapers/${id}/favorite/`, {});
  return;
};

export const unfavoriteWhitepaper = async (id: string): Promise<void> => {
  await del<void>(`/api/whitepapers/${id}/favorite/`);
  return;
};

export const requestWhitepaperDownload = async (id: string): Promise<void> => {
  await post<void>(`/api/whitepapers/${id}/download/`, {});
  return;
};

export const attendSeminar = async (id: string): Promise<void> => {
  await put<void>(`/api/seminars/${id}/attendance/`, {});
  return;
};

export const unattendSeminar = async (id: string): Promise<void> => {
  await del<void>(`/api/seminars/${id}/attendance/`);
  return;
};

export const attendLecture = async (id: string): Promise<void> => {
  await put<void>(`/api/lectures/${id}/attendance/`, {});
  return;
};

export const unattendLecture = async (id: string): Promise<void> => {
  await del<void>(`/api/lectures/${id}/attendance/`);
  return;
};

export const requestCaseDownload = async (id: string): Promise<void> => {
  await post<void>(`/api/cases/${id}/download/`, {});
  return;
};

export const getAddressFromZipCode = async (zipCode: string): Promise<ZipCodeObject | null> =>
  get<ZipCodeObject>(`/api/zipCodes/${zipCode.replace(/-/g, '')}/`).catch(() => null);

export const getPrefectures = async (): Promise<PrefectureObject[]> =>
  get<PrefectureObject[]>('/api/userAttributes/prefectures/');

export const getAges = async (): Promise<UserAgeObject[]> => get<UserAgeObject[]>('/api/userAttributes/ages/');

export const getPermissions = async (): Promise<UserPermissionObject[]> =>
  get<UserPermissionObject[]>('/api/userAttributes/permissions/');

export const getBusinessScales = async (): Promise<BusinessScaleObject[]> =>
  get<BusinessScaleObject[]>('/api/userAttributes/businessScales/');

export const getPositions = async (): Promise<UserPositionObject[]> =>
  get<UserPositionObject[]>('/api/userAttributes/positions/');

export const getCompetitors = async (): Promise<CompetitorObject[]> =>
  get<CompetitorObject[]>('/api/userAttributes/competitors/');

export const getIndustries = async (): Promise<IndustryObject[]> =>
  get<IndustryObject[]>('/api/userAttributes/industries/');

export const getMediaCategories = async (): Promise<WebMediaCategoryObject[]> =>
  get<WebMediaCategoryObject[]>('/api/userAttributes/industryMediaCategories/');

export const createGuestContact = async (params: CreateGuestContactParams): Promise<ContactObject> =>
  post<ContactObject>('/api/contacts/', params);

export const createContact = async (params: CreateContactParams): Promise<ContactObject> =>
  post<ContactObject>('/api/contacts/', params);

// 招待経由の会員登録用のAPI
export const createInvitedUser = async (
  email: string,
  password: string,
  params: UpdateAccountParams
): Promise<SuccessPayload> => post<SuccessPayload>('/api/users/invited/regist', { email, password, params });

// 資料共有ログ
export const createSharedLog = async (
  fromUserId: string,
  toUserId: string,
  sharedUrl: string
): Promise<SuccessPayload> => post<SuccessPayload>('/api/users/shared/log', { fromUserId, toUserId, sharedUrl });

// 問い合わせバナークリックからの問い合わせ
export const createInquiry = async (kind: InquiryType): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/inquiries/', { kind });

// 問い合わせバナークリックログ
export const createInquiryBannerClickLog = async (kind: InquiryType): Promise<SuccessPayload> =>
  post<SuccessPayload>('/api/inquiries/bannerClickLog', { kind });
